import {
  SUBMIT_WORKITEM_OBJECT,
  WORKITEM_JOB_TYPE,
} from "@/shared/interface/newItem";
import { apolloClient } from "@/shared/services/graphql";
import {
  GET_JOB_RUN_SUMMARY,
  CREATE_CASE,
  GET_WORK_ITEMS,
  UPDATE_SCHEDULE_CASE_JOB_STATUS,
  REFERENCE_DATA_THREE_TIER,
  REFERENCE_DATA_TWO_TIER,
  GET_APP_LIST,
  GET_SCHEDULE_JOBS,
  GET_SCHEDULE_WORK_ITEMS,
  GET_PERIODIC_JOBS,
  INSERT_EXECUTION_RECORD_FOR_ONDEMAND,
  GET_EXECUTION_BY_EXECUTIONIDS,
  GET_EXECUTION_RECORD_BY_EXECUTIONID,
  GET_JOB_EXECUTION_ID_BY_CASEID,
  GET_CUSTOMER_SUBSCRIPTIONS_INFO_LIST,
  GET_APP_NAME_FOR_JOB,
  UPDATE_WORKITEM_RECORD,
  GET_RUNBOOKEXECUTOR_BY_ID,
  GET_WORK_ITEMS_WITH_REPORT,
} from "@/shared/services/graphql-action";
import { Observable } from "apollo-link";
import moment from "moment";
import { SCHEDULE_TYPE } from "@/shared/interface/newItem";
import { WORKITEM_FILTER_CRITERIA } from "@/shared/interface/workItem";

export const tsGraphQLService = {
  /**
   * Get work item summay by id
   * @param {string} workItemId: the id of current work item
   * @param {string} executionId: the execution_id of periodically job
   * @returns {Observable}
   */
  getWorkItemSummary(
    workItemId: string | (string | null)[],
    executionId = ""
  ): Observable<any> {
    const params = {
      caseId: workItemId,
    } as any;

    if (executionId) {
      params.executionId = executionId;
    }

    return apolloClient.watchQuery({
      query: GET_JOB_RUN_SUMMARY,
      variables: {
        ...params,
      },
    });
  },
  /**
   * create a new work item
   * @param {SUBMIT_WORKITEM_OBJECT} submitObject: the parameters of creating a new work item
   * @returns {Promise}
   */
  createWorkItem(submitObject: SUBMIT_WORKITEM_OBJECT): Promise<any> {
    return apolloClient.mutate({
      mutation: CREATE_CASE,
      variables: {
        insertCase: submitObject,
      },
    });
  },
  /**
   * Get work item list
   * @param formInline: the filter condition of the list
   * @returns { Observable }
   */
  getWorkItemList(formInline: WORKITEM_FILTER_CRITERIA): Observable<any> {
    const params = !formInline?.JobType?.includes(WORKITEM_JOB_TYPE.scheduled)
      ? formInline
      : {
          ...formInline,
          Scheduled_Once: SCHEDULE_TYPE.once,
          Scheduled_Periodically: SCHEDULE_TYPE.periodically,
        };
    const WORKITEMS_QUERY = params?.workItemwithReports
      ? GET_WORK_ITEMS_WITH_REPORT
      : GET_WORK_ITEMS;
    delete params?.workItemwithReports;
    return apolloClient.watchQuery({
      query: formInline?.JobType?.includes(WORKITEM_JOB_TYPE.scheduled)
        ? GET_SCHEDULE_WORK_ITEMS
        : WORKITEMS_QUERY,
      variables: params,
      fetchPolicy: "no-cache",
    });
  },
  /**
   * Update the schedule work item status
   * @param {string} scheduleId: the schedule id
   * @returns {Promise}
   */
  updateScheduleWorkItemStatus(scheduleId: string): Promise<any> {
    return apolloClient.mutate({
      mutation: UPDATE_SCHEDULE_CASE_JOB_STATUS,
      variables: {
        schedule_id: scheduleId,
        schedule_params: {
          status: "Failed",
        },
      },
    });
  },
  /**
   * Get data in reference table
   * Used for commerce-specific option (DTU)
   * @param { string } typeName: the reference data type in DB
   * @param { number } treeLevel: the level of the data
   * @returns {Object} newData: the formated origin Data
   */
  getReferenceData(typeName: string, treeLevel: number): Observable<any> {
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let queryObj;
    switch (treeLevel) {
      case 2:
        queryObj = REFERENCE_DATA_TWO_TIER;
        break;
      default:
        queryObj = REFERENCE_DATA_THREE_TIER;
    }
    return apolloClient.watchQuery<any>({
      query: queryObj,
      variables: {
        type_name: typeName,
        current_time: currentTime,
      },
    });
  },

  /**
   * Get all app list
   * @returns {Promise}
   */
  getAllApps(): any {
    return apolloClient.watchQuery<any>({
      query: GET_APP_LIST,
    });
  },

  /**
   * scheduledJobs filter by status array
   * @param {WORKITEM_FILTER_CRITERIA} params: the filter criteria
   * @returns {Promise}
   */
  getScheduledJobs(params: WORKITEM_FILTER_CRITERIA): Promise<any> {
    return apolloClient.query({
      query: GET_SCHEDULE_JOBS,
      variables: params,
    });
  },

  /**
   * get periodically scheduled jobs with given schedule_id
   * @param {WORKITEM_FILTER_CRITERIA} params includes schedule_id
   * @returns
   */
  getPeriodicalJobs(params: WORKITEM_FILTER_CRITERIA): Promise<any> {
    return apolloClient.query({
      query: GET_PERIODIC_JOBS,
      variables: params,
    });
  },

  /**
   * For On demand job, create a execution record in the cx_iops_agent_job_execution table
   * and set the execution_status as Failed
   * @param {string} id: the work item id
   * @returns Promise
   */
  setExecutionRecords(id: string): Promise<any> {
    return apolloClient.mutate({
      mutation: INSERT_EXECUTION_RECORD_FOR_ONDEMAND,
      variables: {
        work_item_id: id,
        execution_status: "FAILED",
      },
    });
  },

  /**
   *
   * @param params none-null execution_id array
   * @returns
   */
  getExecutionByExecutionIds(params: { ExecutionIds: string[] }): Promise<any> {
    return apolloClient.query({
      query: GET_EXECUTION_BY_EXECUTIONIDS,
      variables: params,
    });
  },

  /**
   *
   * @param params must include caseId,and when include executionId for scheduled work
   * @returns
   */
  getExecutionRecordByExecutionId(params: Record<string, any>): Promise<any> {
    return apolloClient.query({
      query: GET_EXECUTION_RECORD_BY_EXECUTIONID,
      variables: params,
    });
  },

  /**
   *
   * @param params must include runbook_id,
   * @returns Promise of executor info object
   */

  getExecutorInfoFromRundeckId(params: Record<string, any>): Promise<any> {
    return apolloClient.query({
      query: GET_RUNBOOKEXECUTOR_BY_ID,
      variables: params,
    });
  },

  /**
   * @param params must include caseId
   * @returns
   */
  getExecutionIdByCaseId(params: { caseId: string }): Promise<any> {
    return apolloClient.query({
      query: GET_JOB_EXECUTION_ID_BY_CASEID,
      variables: params,
    });
  },

  getCustomerSubscriptionsInfoList(): Promise<any> {
    return apolloClient.query({
      query: GET_CUSTOMER_SUBSCRIPTIONS_INFO_LIST,
    });
  },

  /**
   * @param params must include caseId
   * @returns Promise
   */
  getAppNameForJob(params: { caseId: string }): Promise<any> {
    return apolloClient.query({
      query: GET_APP_NAME_FOR_JOB,
      variables: params,
    });
  },

  /**
   *
   * @param params must include workitem_id, and other fields must aign with hybris_workitem_id
   * @returns
   */
  updateWorkItem(params: Record<string, any>): Promise<any> {
    return apolloClient.mutate({
      mutation: UPDATE_WORKITEM_RECORD,
      variables: params,
    });
  },
};
