
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "LogoutPage",
})
export default class LogoutPage extends Vue {
  logbackIn(): void {
    this.$router.push("/");
  }
}
