
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "GoBack",
  components: {},
})
export default class GoOcc extends Vue {
  /**
   * Open the occ page
   * @returns void
   */
  goOcc(): void {
    if (process.env.VUE_APP_OCC_URL)
      window.location.replace(process.env.VUE_APP_OCC_URL);
  }
}
