import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import IndexPage from "../views/IndexPage.vue";
import LogoutPage from "../views/LogoutPage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Index",
    component: IndexPage,
    redirect: {
      name: "Homepage",
    },
    children: [
      {
        path: "",
        name: "Homepage",
        component: () => import("../views/Homepage/Homepage.vue"),
      },
      {
        path: "/new",
        name: "NewItemPage",
        component: () => import("../views/NewItemPage/NewItemPage.vue"),
      },
      {
        path: "/itemlist",
        name: "ItemListPage",
        component: () => import("../views/ItemListPage/ItemListPage.vue"),
      },
      {
        path: "/detail",
        name: "ItemDetailPage",
        component: () => import("../views/ItemDetailPage/ItemDetailPage.vue"),
      },
      {
        path: "/scheduledlist",
        name: "ScheduleItemListPage",
        component: () =>
          import("../views/ScheduleItemListPage/ScheduleItemListPage.vue"),
      },
    ],
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
