import { CONSENT_PREFIX } from "./ConsentPopupConstants";
export const getConsentedUsers = (): [string, string][] => {
  const reg = new RegExp(CONSENT_PREFIX);
  const locStorage = localStorage;
  const consentedUsers: [string, string][] = [];
  Object.keys(locStorage).forEach((key) => {
    if (reg.test(key)) {
      consentedUsers.push([key, locStorage[key]]);
    }
  });
  return consentedUsers;
};

export const setConsentedUsers = (consentedUsers: [string, string][]): void => {
  consentedUsers.forEach(([k, v]) => {
    localStorage.setItem(k, v);
  });
};
