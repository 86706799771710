import http from "@/shared/services/http.js";
import { from } from "rxjs";
import { map } from "rxjs/operators";

export const commonService = {
  /**
   * get casl policy based on current user roles
   */
  getCASLPolicy(): any {
    const getPromise = http.get(`${process.env.VUE_APP_AUTH_URL}/role-policy`);
    return from(getPromise).pipe(
      map((data: any) => {
        return data;
      })
    );
  },

  /**
   * get cached logs from redis during execution of workitem
   */
  getCachedLogs(executeId: string): Promise<any> {
    return http.get(
      `${process.env.VUE_APP_DATA_STREAM}/cachedLog/${executeId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.VUE_APP_APIM_KEY,
        },
      }
    );
  },

  /**
   * Temporary: get realtime log from old redis logic for rundeck
   */
  getCachedRealTimeLog(executeId: string | (string | null)[]): any {
    return http.get(`${process.env.VUE_APP_REDIS}/${executeId}`);
  },
};
