
import { Component, Vue } from "vue-property-decorator";
import { expiredStorage } from "../../utils/index";
import { CONSENT_PREFIX, EXPIRED_TIME } from "./ConsentPopupConstants";

@Component({
  name: "ConsentPopup",
  components: {},
})
export default class ConsentPopup extends Vue {
  dialogVisible = true;
  choice = 1;

  onClose(): void {
    const { sub }: { sub: string } = JSON.parse(
      localStorage.getItem("auth_jwt_decoded") as string
    );
    if (this.choice === 1) {
      expiredStorage.setItem(CONSENT_PREFIX + sub, "true", EXPIRED_TIME);
      this.goHomePage();
    } else {
      this.logOut();
    }
    this.dialogVisible = false;
  }

  logOut(): void {
    this.$router.push("/logout");
  }

  goHomePage(): void {
    if (this.$route.path === "/") {
      this.$router.go(0);
    } else {
      this.$router.push("/").then(() => {
        this.$router.go(0);
      });
    }
  }
}
