/**
 * get the externalSystem name for the userid if userid has external system mapping
 * @returns externalSystemName string
 */
export function getExternalSystemNameForUser(ownerId: string): string {
  const userIDNameStr = process.env.VUE_APP_USERID_SYSNAME_MAP;
  if (!userIDNameStr) return ownerId;
  const userIdSysNameMap = JSON.parse(userIDNameStr);
  const externalNameIndex = userIdSysNameMap.findIndex(
    (item) => item.userId === ownerId
  );
  return externalNameIndex !== -1
    ? userIdSysNameMap[externalNameIndex].externalSystemName
    : ownerId;
}
