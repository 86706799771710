
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "GoBack",
  components: {},
})
export default class GoBack extends Vue {
  showGoBack = false;

  @Watch("$route", { deep: true, immediate: true })
  onRouteChange(val: Record<string, any>): void {
    this.showGoBack =
      val.name === "Homepage" || val?.query?.newTab === "true" ? false : true;
  }

  goBack(): void {
    if (window?.history?.length <= 2) {
      this.$router.push("/");
    } else {
      this.$router.go(-1);
    }
  }
}
