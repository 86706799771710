interface ExpiredStorageInterface {
  readonly storage: globalThis.Storage;
  readonly prefix: string;
  setItem(key: string, value: string, expiration?: number);
  getItem(key: string): string | null;
}

interface ExpiredStorageConstructor {
  new (storage: globalThis.Storage, prefix: string): ExpiredStorageInterface;
}

function createExpiredStroage(
  Ctor: ExpiredStorageConstructor,
  storage: globalThis.Storage,
  prefix: string
): ExpiredStorageInterface {
  return new Ctor(storage, prefix);
}

interface ExpiredStorageValue {
  value: string;
  createDate: number;
  expirationDate?: number;
}

function getTimeStamp() {
  return Math.floor(new Date().getTime() / 1000);
}

function checkExpiration(value: ExpiredStorageValue): boolean {
  if (!value.expirationDate) {
    return false;
  }
  const timeLeft = value.expirationDate - getTimeStamp();
  return timeLeft <= 0;
}

function createValue(value: string, expiration?: number): ExpiredStorageValue {
  const createDate = getTimeStamp();
  if (expiration !== undefined) {
    return {
      value,
      createDate,
      expirationDate: createDate + expiration,
    };
  }
  return {
    value,
    createDate,
  };
}

class ExpiredStorage implements ExpiredStorageInterface {
  readonly storage: globalThis.Storage;

  readonly prefix: string;

  constructor(storage: globalThis.Storage, prefix: string) {
    this.storage = storage;
    this.prefix = prefix;
  }

  createKey(key: string) {
    return this.prefix + key;
  }

  setItem(key: string, value: string, expiration?: number) {
    const keyWithPrefix = this.createKey(key);
    const valueWithExpiration = createValue(value, expiration);
    return this.storage.setItem(
      keyWithPrefix,
      JSON.stringify(valueWithExpiration)
    );
  }

  getItem(key: string): string | null {
    const keyWithPrefix = this.createKey(key);
    const value = this.storage.getItem(keyWithPrefix);
    if (value !== null) {
      const valueWithDate = JSON.parse(value) as ExpiredStorageValue;
      if (checkExpiration(valueWithDate)) {
        this.storage.removeItem(keyWithPrefix);
        return null;
      }
      return valueWithDate.value as string;
    }
    return null;
  }
}

export const expiredStorage = createExpiredStroage(
  ExpiredStorage,
  localStorage,
  "EXPIRED_STORAGE_PREFIX_"
);
