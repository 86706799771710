import axios from "axios";

const http = axios.create({
  timeout: 120000,
  withCredentials: false,
});
http.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
http.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("auth_jwt");
    token && (config.headers.Authorization = "Bearer " + token);
    if (config.url.indexOf("/cionb/case/download/files") > -1) {
      config.timeout = 60 * 60 * 24 * 1000;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.error(error);
  }
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      const { exp = 0 } = localStorage.getItem("auth_jwt_decoded");
      if (exp * 1000 < new Date().getTime()) {
        localStorage.clear();
        return window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export default http;
