
import { Component, Vue } from "vue-property-decorator";
import GoOcc from "../shared/components/GoOcc.vue";

@Component({
  name: "UnAuthorizedPage",
  components: {
    GoOcc,
  },
})
export default class UnAuthorizedPage extends Vue {
  camURL = `${process.env.VUE_APP_CAM_URL}`;
  camWIKI = `${process.env.VUE_APP_CAM_WIKI}`;

  reloadPage(): void {
    localStorage.clear();
    location.reload();
  }
}
