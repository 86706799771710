
import { Component, Vue } from "vue-property-decorator";
import CommonHeader from "../shared/components/CommonHeader.vue";
import UnAuthorizedPage from "../views/UnAuthorizedPage.vue";
import GoBack from "../shared/components/GoBack.vue";
import { commonService } from "@/shared/services/api/common";
import { signalRService } from "@/shared/services/websocket";
import VueJwtDecode from "vue-jwt-decode";
import store from "@/store/index";
import { Auth } from "../shared/services/auth";
import { tsGraphQLService } from "@/shared/services/api/trouble-shooting-graphQL";
// import ChatAssistant from "@/shared/components/ChatAssistant.vue";
import { Route } from "vue-router";
import ConsentPopup from "../views/ConsentPopup/ConsentPopup.vue";
import { expiredStorage } from "../utils/index";
import { CONSENT_PREFIX } from "../views/ConsentPopup/ConsentPopupConstants";

Component.registerHooks(["beforeRouteEnter"]);
@Component({
  name: "IndexPage",
  components: {
    CommonHeader,
    GoBack,
    UnAuthorizedPage,
    ConsentPopup,
    // ChatAssistant,
  },
})
export default class IndexPage extends Vue {
  appLoading = true;
  initFlag = false;
  isAuthorized = false;
  isConsent = this.checkConsent();

  created(): void {
    this.setStateInStore();

    signalRService.connectToSignalR();
    this.getAllAppList();

    signalRService.initWs();
  }

  beforeUpdate(): void {
    this.removeURLtoken();
  }

  beforeRouteEnter(to: Route, from: Route, next): void {
    if (
      localStorage.getItem("auth_jwt") &&
      localStorage.getItem("auth_jwt_decoded")
    )
      next();
    else {
      if (!to.hash.includes("sso")) {
        sessionStorage.setItem("redirectPath", to.fullPath);
        const redirectURL = encodeURIComponent(
          `${window.location.origin}/#/sso/`
        );
        const authBaseURL = process.env.VUE_APP_AUTH_URL;
        window.location.replace(
          `${authBaseURL}/login?RelayState=${redirectURL}`
        );
      } else {
        const params = to.hash.split("/");
        const token = params[params.length - 1];
        localStorage.setItem("auth_jwt", token);

        commonService.getCASLPolicy().subscribe(
          (res) => {
            if (res.status === 200 && res.data) {
              localStorage.setItem(
                "auth_jwt_decoded",
                JSON.stringify({
                  ...VueJwtDecode.decode(token),
                  rules: res.data.rules,
                })
              );
              const redirectPath = sessionStorage.getItem("redirectPath");
              if (redirectPath) next({ path: redirectPath });
              else next();
            }
          },
          () => {
            localStorage.setItem(
              "auth_jwt_decoded",
              JSON.stringify(VueJwtDecode.decode(token))
            );
            next();
          }
        );
      }
    }
  }

  setTokenToLocalStorage(token: string): void {
    localStorage.setItem("auth_jwt", token);
    localStorage.setItem(
      "auth_jwt_decoded",
      JSON.stringify(VueJwtDecode.decode(token))
    );
  }

  removeURLtoken(): void {
    const query = Object.assign({}, this.$route.query);
    delete query.token;
    this.$router.replace({ query });
  }

  setStateInStore(): void {
    store.commit("setUserProfile");
  }

  /**
   * show the login popup for user to reLogin
   * @returns void
   */
  showLoginPopup(): void {
    this.$alert(
      `<p>Please click <a href=${process.env.VUE_APP_OCC_URL} style="text-decoration:underline; color:#397490">here</a> for SSO login</p>`,
      "Login",
      {
        dangerouslyUseHTMLString: true,
        showCancelButton: false,
        showConfirmButton: false,
        showClose: false,
      }
    );
  }

  /**
   * Get all app list of automation workspace
   * @returns void
   */
  getAllAppList(): void {
    tsGraphQLService.getAllApps().subscribe(
      (res) => {
        if (res?.data?.hybris_workspace_application_info) {
          let appList = res.data.hybris_workspace_application_info;
          appList = Auth.getAuthorizedAppList(appList);
          this.isAuthorized = Auth.isUserAuthorized(appList);

          if (!this.isAuthorized) {
            localStorage.clear();
          }
          store.commit("setAppList", appList);
        }
        this.setCurrentApp();
        this.appLoading = false;
      },
      (error) => {
        // no permission for hasura graphql to fetch app list
        const noPermission = error?.message?.includes("query_root");
        if (noPermission) {
          this.appLoading = false;
          return;
        }

        // awt_auth token invalidate
        const status = error?.networkError?.statusCode;
        if (status === 401) {
          location.reload();
        }

        // awt_auth token expired
        localStorage.clear();
        location.reload();
      }
    );
  }

  /**
   * set current App in store
   * @returns void
   */
  setCurrentApp(): void {
    const currentApp = localStorage.getItem("currentApp") || "{}";
    const firstAppObj = store.state.appList[0] || {};
    const targetApp =
      currentApp && currentApp !== "{}" ? JSON.parse(currentApp) : firstAppObj;
    store.commit("setCurrentApp", targetApp);
    this.initFlag = true;
  }
  /**
   * check whether show pop-up or not
   * @reuturns boolean
   */
  checkConsent(): boolean {
    const { sub }: { sub: string } = JSON.parse(
      localStorage.getItem("auth_jwt_decoded") as string
    );
    if (expiredStorage.getItem(CONSENT_PREFIX + sub) === null) {
      return false;
    }
    return true;
  }
}
