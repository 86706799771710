import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { apolloClient } from "./shared/services/graphql";
import locale from "element-ui/lib/locale/lang/en";
import Multiselect from "vue-multiselect";
import VueRouter from "vue-router";
import VCalendar from "v-calendar";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import "./assets/element-variables.scss";
import "./assets/public.style.scss";
import "vue-multiselect/dist/vue-multiselect.min.css";

import VueApollo from "vue-apollo";
import {
  getConsentedUsers,
  setConsentedUsers,
} from "./views/ConsentPopup/ConsentStorageControl";

Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.component("multiselect", Multiselect);
Vue.use(VueApollo);
library.add(far);
library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

const clearStorage = localStorage.clear.bind(localStorage);
localStorage.clear = () => {
  const consentUsers = getConsentedUsers();
  clearStorage();
  setConsentedUsers(consentUsers);
};

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
Vue.use(VCalendar, {
  componentPrefix: "vc",
});

declare module "vue/types/vue" {
  interface Vue {
    $router: VueRouter;
  }
}

new Vue({
  router,
  store,
  provide: apolloProvider.provide(),
  render: (h) => h(App),
}).$mount("#app");
