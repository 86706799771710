import { ApolloClient, DefaultOptions } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import { ApolloLink } from "apollo-link";
import Vue from "vue";

Vue.use(VueApollo);

const apiLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL,
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("auth_jwt");
  operation.setContext({
    headers: {
      Authorization: `Bearer ${token}` || null,
    },
  });
  return forward(operation);
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
  },
  query: {
    fetchPolicy: "network-only",
  },
};

export const apolloClient = new ApolloClient({
  link: middlewareLink.concat(apiLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
